'use strict';

angular.module('sdk').service('consoleService', function(_) {

    function stringify(args) {
        return _.toArray(args).map(function(arg) {
            if (typeof arg == 'object') return JSON.stringify(arg);
            return '' + arg;
        }).join(' ');
    }

    function createCustomError(name, message) {
        function CustomError(message) {
          this.name = name;
          this.message = message;
        }

        CustomError.prototype = new Error();
        CustomError.prototype.constructor = CustomError;

        return new CustomError(message);
    }

    this.logEvent = function(category, action, label) {
        if (!category || !action) {
            throw createCustomError('ConsoleServiceError', 'Both `category` and `action` arguments are required');
        }
        label = label || null;

        if (window._gaq) {
              _gaq.push(['_trackEvent', category, action, label, null, true]);
        } else if (window.ga) {
            ga('send', 'event', {
                eventCategory: category,
                eventAction: action,
                eventLabel: label,
                eventValue: null,
                nonInteraction: true
            });
        }

        var arr = [category, action];
        if (label) arr.push(label);
        console.log(arr.join(', '));
    };

    this.reportError = function(type, err) {
        if (!err) {
            err = type;
            type = 'CustomError';
        }

        if (!(err instanceof Error)) {
            err = createCustomError(type, stringify(_.rest(arguments)));
        }

        if (window.NREUM) {
            NREUM.noticeError(err);
        }

        if (window.Bugsnag) {
            Bugsnag.notifyException(err, type);
        }

//        if (window._gaq || window.ga) {
//            this.logEvent('Error', type, stringify([err.message, err.stack]));
//        }

        console.error(type, '-', err.message, '\n', err.stack);
    };
});
