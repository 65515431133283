'use strict';

angular.module('sdk').controller('MainController', function(
    $scope,
    $rootScope,
    $http,
    $location,
    $route,
    $routeParams,

    projectConfig,
    projectIdHandler,
    templateIdHandler,
    designData,
    updateDesignData,
    popupService,
    iframeService,
    printService,
    getDesignDataTemplate,
    configService,
    warningService,
    menuService
){
    var isEmptyMenu = true;
    var isInitialRoute = true;

    $scope.templateID = undefined;
    $scope.projectID = undefined;
    $scope.menuName = undefined;
    $scope.isNavigationVisible = false;

    $scope.openPopup = popupService.openPopup;

    // NAVIGATION
    $scope.$on('$routeChangeSuccess', function () {
        isEmptyMenu = !$routeParams.menuName
        $scope.menuName = isEmptyMenu ? projectConfig.defaultMenu : $routeParams.menuName

        $routeParams.oldProjectID && ($routeParams.projectID = $routeParams.oldProjectID.toLowerCase());

        if ($routeParams.projectID) {
            setDesignID($routeParams.projectID);
            $scope.projectID = $routeParams.projectID;
        } else if ($routeParams.templateID) {
            setTemplateID($routeParams.templateID);
        }

        menuService.checkAsVisited($scope.menuName);

        if(isInitialRoute){
            if(!$routeParams.templateID && !$routeParams.projectID){
                setTemplateID(templateIdHandler.getTemplateID())
            }
            isInitialRoute = false
        }

        // Google Analytics
        ga('send', 'pageview', window.location.pathname + $location.path());
    });



    // PROJECT
    function setDesignID(projectID) {
        $scope.projectID = projectID;
        $scope.templateID = null;
    }

    $scope.$watch('projectID', projectIdHandler.loadProject);

    $scope.$watch(projectIdHandler.getProjectID, function (projectID) {
        $scope.projectID = projectID;
    });



    // TEMPLATE
    function setTemplateID(templateID) {
        $scope.templateID = templateID;
        $scope.projectID = null;

        if (projectIdHandler.getProjectID()) {
            // We were on a project but now moving to a template.
            // Changing just the template properties is not enough - must unset the project and load a blank template
            projectIdHandler.unsetProjectID();
            updateDesignData(getDesignDataTemplate(templateID));
        }
    }

    $scope.$watch('templateID', function (templateID) {
        templateIdHandler.applyTemplate(templateID)
    });

    $scope.$watch(templateIdHandler.getTemplateID, function (templateID) {
        if(isInitialRoute)
            return
        if (templateID && !$scope.projectID) {
            setTemplateID(templateID);
        }
    });



    // CONTENT
    $scope.$on('ngRenderFinished', function(e) {
        $scope.$broadcast('contentReady');
    });



    // MENU
    $scope.$on('changeMenu', function changeMenu(e, menuName) {
        $scope.menuName = menuName;
        isEmptyMenu = false
    });

    $scope.$watch('menuName', function (menuName) {
        // TODO: this code affects nothing
        if (!menuName) {
            $rootScope.isMobile = false;
            $rootScope.isNavigationVisible = false;
        }
    });



    $scope.toggleNavigation = function () {
        $scope.isNavigationVisible = !$scope.isNavigationVisible;
    };


    // URL path change
    $scope.$watch(getUrlPath, function (path) {
        if(!path)
            return
        if (isEmptyMenu && !$scope.projectID) {
            return;
        }

        $location.path(path);
    });

    function getUrlPath() {
        var pathParts = [];

        if ($scope.projectID) {
            pathParts.push('design', $scope.projectID);
        } else if ($scope.templateID) {
            pathParts.push($scope.templateID);
        }

        if ($scope.menuName && !isEmptyMenu) {
            pathParts.push($scope.menuName);
        }

        return pathParts.join('/');
    }
});
