'use strict';

angular.module('sdk').directive('tileList', function (_) {
    return {
        restrict: 'E',
        scope: {
            enableProperty: '=',
            optionData: '=',
            tileName: '@',    // CSS class for tile, eg. 'tiles__item_fontStyle'
            unselectable: '@', // allows to deselect tile
            embed: '@' // set icon as CSS (with background-image style) or HTML (include a piece of HTML code)
        },
        replace: true,
        templateUrl: function(element, attributes) {
            return (attributes.tileListTemplate) ? attributes.tileListTemplate : 'tileList.html';
        },
        compile: function(element, attributes, transclude) {
            element.find('tile').attr('tile-template', attributes.tileTemplate);
        },
        controller: function($scope, $element, $attrs) {
            var itemList = $scope.optionData.legalValues(),
                expandableCount = parseInt($attrs.expandable, 10) || 0;

            // defaults
            $scope.tileType = $attrs.tileType || 'standard';         // defines tile behaviour when selecting: 'checkbox', 'standard', etc.
            $scope.tileTemplate = $attrs.tileTemplate || 'standard'; // tile HTML template: 'color', 'standard', etc. — eg. 'colorTile.html'
            $scope.tileScale = $attrs.tileScale || '1x1';            // tile scale: '1x1', '3x1', etc. — eg. 'tiles__item_1x1'
            $scope.textPosition = $attrs.textPosition || 'top';      // tile text location: 'top', 'middle', 'bottom' — eg. 'tiles__text_top'
            $scope.textShow = $attrs.textShow || true;               // hides or shows tile text
            $scope.tileColumns = $attrs.tileColumns || 'squared';    // tile list column count — eg. 'square', 'double', 'triple', 'quadruple'

            $scope.expanded = false;

            // tile list can not be expandable if there is nothing to expand
            $scope.expandable = (expandableCount && (itemList.length > expandableCount)) ? expandableCount : false;

            $scope.selectItem = function(itemCode, forcedValue) {
                switch ($scope.tileType) {
                    case 'checkbox': {
                        if (forcedValue !== undefined) {
                            $scope.optionData.setValueEnabled(itemCode, !!forcedValue);
                        }
                        else {
                            $scope.optionData.toggleValue(itemCode);
                        }
                    }
                    break;

                    case 'standard': {
                        if (($scope.unselectable === 'true') && ($scope.optionData.value() === itemCode)) {
                            $scope.optionData.value('none', true);
                        } else {
                            $scope.optionData.value(itemCode, true);
                        }
                    }
                    break;
                }

                if ($scope.enableProperty) {
                    $scope.enableProperty.value($scope.optionData.hasValue() ? 'on' : 'off')
                }

                $scope.$emit('selectItem', itemCode);
            };

            $scope.isSelected = function(itemCode) {
                var selected;

                if ($scope.enableProperty && ($scope.enableProperty.value() === 'off')) {
                    return false;
                }

                switch ($scope.tileType) {
                    case 'checkbox': { selected = _.contains($scope.optionData.valueList(), itemCode); } break;
                    case 'standard': { selected = ($scope.optionData.value() === itemCode); } break;
                }

                return selected;
            };

            $scope.disable = function() {
                $scope.enableProperty.value('off', true);
            };

            $scope.toggleExpand = function () {
                $scope.expanded = !$scope.expanded;
                $scope.itemList = sliceItemList();
            };

            $scope.$watch('optionData.legalValues()', function (values) {
                $scope.itemList = sliceItemList();
            }, true);

            function sliceItemList() {
                var items = $scope.optionData.legalValues(),
                    itemCount = $scope.expandable - 1;

                if ($scope.expandable) {
                    if ($scope.expanded) {
                        itemCount = items.length;
                    }

                    items = items.slice(0, itemCount);
                }

                return items;
            }
        }
    }
});
