'use strict';

angular.module('sdk').factory('urlService', function (_, $window, $location, projectIdHandler, templateIdHandler, vendorConfig, configService) {

    function isSelf() {
        return !vendorConfig.landingPageUrl || vendorConfig.landingPageUrl === 'none' || getCleanUrl(vendorConfig.landingPageUrl) === getCleanUrl($window.location.host + $window.location.pathname);
    }

    function getRootUrl() {
        return getCleanUrl($window.location.host);
    }

    function getBaseUrl(clean) {
        var url = isSelf() ? $window.location.host + $window.location.pathname : vendorConfig.landingPageUrl;
            url = clean ? getCleanUrl(url) : url;

        return url;
    }

    function getShareUrl(clean) {
        var projectID = projectIdHandler.getProjectID(),
            path = (projectID) ? 'design/' + projectID : templateIdHandler.getTemplateID();

        var baseUrl = getBaseUrl(clean);
        if (isSelf()) {
            var url;
            if (vendorConfig.html5Mode) {
                url = baseUrl;
            } else {
                // on local urls  use hashbang only if supported
                url = baseUrl + '#' + vendorConfig.hashPrefix + '/' + path;
            }

            if (configService.externalConfigString) {
                url += ('?config=' + configService.externalConfigString);
            }

            return url;
        } else {
            // for external landing pages always use non-html5Mode with hashbang
            return baseUrl + '#!/' + path;
        }
    }

    function getProjectID() {
        return projectIdHandler.getProjectID();
    }

    function getFileName(url) {
        return _.last(url.split('/'));
    }

    function getCleanUrl(url) {
        var protocol = $window.location.protocol;

        if (/^http/.test(url)) {
            return url;
        }

        url = protocol + '//' + url;
        url = url.replace(/(\/\/)+/, '//');

        return url;
    }

    return {
        'getRootUrl': getRootUrl,
        'getBaseUrl': getBaseUrl,
        'getShareUrl': getShareUrl,
        'getProjectID': getProjectID,
        'getFileName': getFileName,
        'getCleanUrl': getCleanUrl
    }
});
