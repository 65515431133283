'use strict';

angular.module('sdk').directive('vendorOrderForm', function (
    _,
    $timeout,
    vendorConfig,
    getModifiedDesignDataForOrder,
    designDataOutput,
    extractGetParameters,
    objectToDashed,
    flattenDashed,
    previewUploader,
    projectIdHandler,
    getOrderData
) {
    return {
        restrict: 'E',
        scope: {},
        replace: true,
        require: '^order',
        templateUrl: 'vendorOrderForm.html',
        link: function(scope, element, attrs, orderCtrl) {

            scope.execute = function() {
                var httpMethod = vendorConfig.shoppingCartMethod.replace('JSON-', '');
                var isJson = vendorConfig.shoppingCartMethod.indexOf('JSON-') === 0;

                var exportData = getModifiedDesignDataForOrder(designDataOutput);
                var formFields = [];

                function pushField(value, name) {
                    formFields.push({
                        'name': name,
                        'value': value
                    });
                }

                // extracting GET parameters from vendor config's "shoppingCartUrl" parameter
                if (httpMethod === 'GET') {
                    formFields = extractGetParameters(vendorConfig.shoppingCartUrl);
                }

                // all keys in data object should be in dashed notation
                exportData = objectToDashed(exportData);

                // GET and POST data object should be also flattened
                if (vendorConfig.shoppingCartMethod.search('JSON') === -1) {
                    exportData = flattenDashed(exportData);
                }

                // extending export data with additional parameters
                previewUploader && _.extend(exportData, previewUploader.getData());
                vendorConfig && _.extend(exportData, vendorConfig.optionalParameters);
                getOrderData && _.extend(exportData, getOrderData());

                if (isJson) {
                    pushField((httpMethod === 'POST') ? exportData : JSON.stringify(exportData), 'json');
                } else {
                    _.each(exportData, pushField);
                }


                scope.formFields = formFields;

                element.attr('method', httpMethod);
                element.attr('action', vendorConfig.shoppingCartUrl);
                $timeout(function () { // must delay to allow scope formFields to populate
                    element[0].submit();
                });
            };

            orderCtrl.registerVendorOrderFormScope(scope);
        }
    }
});
