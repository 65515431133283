'use strict';

angular.module('sdk', ['ngRoute', 'ngAnimate', 'angulartics', 'angulartics.google.analytics', 'angular-bugsnag']);

angular.module('default-routes',[]).config(function ($locationProvider, $routeProvider) {
    var customizerConfig = window.customizerConfig;
    $locationProvider.hashPrefix(customizerConfig.hashPrefix || '');
    $locationProvider.html5Mode(!!customizerConfig.html5Mode);

    $routeProvider
        .when('/', {
        })
        .when('/save', {
        })
        .when('/order', {
        })
        .when('/design/:projectID', {
            reloadOnSearch: false
        })
        .when('/design/:projectID/:menuName', {
            reloadOnSearch: false
        })
        .when('/:templateID', {
            reloadOnSearch: false
        })
        .when('/:templateID/:menuName', {
            reloadOnSearch: false
        })
        .otherwise({
            redirectTo: '/'
        });
});