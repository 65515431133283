'use strict';

angular.module('sdk').factory('getHexFromCode', function (colorList) {

    return function getHexFromCode(code) {
        var hex = '#ffffff';

        if (code in colorList.itemMap) {
            hex = colorList.itemMap[code].hex;
        }

        return hex;
    }

});
