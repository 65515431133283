'use strict';

angular.module('sdk').factory('previewService', function (_, $location, previewAdapters) {

    var forcedAdapterName = $location.search().preview,
        activeAdapter;

    function getAdapter() {
        if (activeAdapter) {
            return activeAdapter;
        }

        activeAdapter = _.find(previewAdapters.adapters, function (adapter, name) {
            return forcedAdapterName ? name === forcedAdapterName : adapter.supported();
        });

        return activeAdapter;
    }

    function getSnapshots() {
        return activeAdapter.getSnapshots();
    }

    return {
        'getAdapter': getAdapter,
        'getSnapshots': getSnapshots
    }
});
