'use strict';

angular.module('sdk').factory('FileProperty', function (_, imageServerPath) {
    var FileProperty = function () {
        this.$path = null;
        this._value = null;
        this.isUserDefined = false;
    };

    angular.extend(FileProperty.prototype, {
        value: function () {
            if (arguments.length > 0) {
                return this._setValue(arguments[0], arguments[1]);
            } else {
                return this._value;
            }
        },

        lastRealValue: function (fallbackValue) {
            var realValue = _.find(this._valueHistory, function (value) {
                return (!!value && !!value.id);
            });
            return realValue || fallbackValue;
        },

        hasValue: function () {
            return (!!this._value && !!this._value.id);
        },

        url: function () {
            return this._value ? imageServerPath + '/' + this._value.id + '/' + this._value.filename : 'Not Specified';
        },

        path: function () {
            return this._value ? this._value.id + '/' + this._value.filename : 'Not Specified';
        },

        _setValue: function (value, isUserDefined) {
            this.isUserDefined = isUserDefined || this.isUserDefined;

            if (this._value === value) {
                return this;
            }

            this._value = value;

            return this;
        }
    });

    return FileProperty;
});
