'use strict';

// The typical flow in the order process that allows some of the tasks to be skipped if their executors are not present
angular.module('sdk').directive('order', function (
    _,
    $q,
    $timeout,
    dataModelVersion,
    designDataOutput,
    designDataUtils,
    projectIdHandler,
    projectOrderer,
    previewUploader,
    popupService,
    vendorConfig,
    flattenDashed,
    objectToDashed,
    extractGetParameters,
    getModifiedDesignDataForOrder,
    iframeService
) {
    return {
        restrict: 'E',
        // replace: true,
        templateUrl: 'order.html',
        controller: function($scope) {
            this.registerVendorOrderFormScope = function(scope) {
                $scope.vendorOrderFormScope = scope;
            };
            this.registerContactFormScope = function(scope) {
                $scope.contactFormScope = scope;
            };
        },
        link: function(scope, element, attributes) {
            scope.orderType = vendorConfig.shoppingCartMethod;
            scope.orderComplete = false;
            scope.status = 'processing';
            scope.statusMessage = 'Saving...';

            function gatherContactData() {
                if(scope.orderType !== 'email')
                    return;
                if(!scope.contactFormScope)
                    return;
                return scope.contactFormScope.activate()
            }

            function saveProject() {
                scope.statusMessage = 'Saving the project...';
                scope.errorMessage = 'Error! Failed to save the project...'

                return projectIdHandler.saveProject()
                    .then(function() {
                        scope.statusMessage = null;
                    });
            }

            function uploadSnapshots() {
                if(!previewUploader)
                    return;

                scope.statusMessage = 'Uploading order images...';
                scope.errorMessage = 'Error! Failed to upload order images...'

                return previewUploader.upload(projectIdHandler.getProjectID())
                    .then(function() {
                        scope.statusMessage = null;
                    });
            }

            function makeApiOrder(resultData) {
                var contactFormData = resultData[0],
                    snapshotData = resultData[1];
                scope.statusMessage = 'Processing the inquiry...';
                scope.errorMessage = 'Error! Failed to create order...'
                popupService.lockPopup(true);

                console.log('snapshotData', snapshotData);

                var data = {
                  vendor: vendorConfig.id,
                  recipeId: snapshotData.id,
                  modelVersion: dataModelVersion,
                  method: vendorConfig.orderMethod,
                  contactForm: contactFormData,
                }

                return projectOrderer.order(data);
            }

            function makeVendorOrder(apiOrderResult) {

                if(scope.vendorOrderFormScope && scope.orderType !== 'email' && scope.orderType !== 'direct')
                {
                    scope.statusMessage = 'Redirecting...';
                    scope.errorMessage = 'Error! Failed to execute vendor order method...';
                    scope.vendorOrderFormScope.execute();
                    scope.status = 'success';
                    scope.orderComplete = true;
                }
                else if(apiOrderResult.data.url)
                {
                    scope.statusMessage = 'Redirecting...';
                    scope.errorMessage = 'Error! Failed to redirect...';
                    if (iframeService.isInsideIframe()) {
                        iframeService.sendMessage('redirectTo', encodeURIComponent(apiOrderResult.data.url));
                    } else {
                        location.href = apiOrderResult.data.url;
                    }
                    scope.status = 'success';
                    scope.orderComplete = true;
                }
                else if(scope.orderType === 'email')
                {
                    if(apiOrderResult.data.errors)  {
                        scope.statusMessage = 'Failed to send inquiry emails...';
                        scope.status = 'error';
                    } else {
                        scope.statusMessage = 'Done!';
                        popupService.changeTitle('Contact details submitted!');
                        scope.status = 'success';
                        scope.orderComplete = true;
                    }
                }
                else
                {
                    scope.statusMessage = 'Error! Could not locate order method.';
                    console.error('"vendorOrderFormScope" is not defined');
                }
            }

            function orderErrorHandler(error) {
                scope.status = 'error';
                scope.statusMessage = scope.errorMessage;
            }

            function unlockPopup() {
                popupService.lockPopup(false);
            }

            $q.all([
                gatherContactData(),
                saveProject()
                .then(uploadSnapshots)
            ])
            .then(makeApiOrder)
            .then(makeVendorOrder)
            .catch(orderErrorHandler)
            .finally(unlockPopup);
        }
    }
});
