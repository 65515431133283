'use strict';

angular.module('sdk').factory('projectOrderer', function (_, $http, RequestCanceler, projectIdHandler, loadSaveOrderConfig, vendorConfig, consoleService) {
    var requestCanceler = new RequestCanceler();

    function order(orderData) {
        requestCanceler.cancel();

        var url = getOrderUrl();

        return $http({
            'method': 'POST',
            'url': url,
            'data': orderData,
            'timeout': requestCanceler.promise
        })
        .error(orderError);
    }

    function cancel() {
        requestCanceler.cancel();
    }

    function orderError(data, status, headers, config) {
        consoleService.reportError('HttpPostError', 'Unable to make order (status: ' + status + ', message: ' + (data.error || data) + ')', config.url, config.data);
    }

    function getOrderUrl() {
        return _.template(loadSaveOrderConfig.orderUrl)({
            'projectID': projectIdHandler.getProjectID(),
            'vendor': vendorConfig.vendor
        });
    }

    return {
        'order': order,
        'cancel': cancel
    }
});
