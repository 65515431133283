'use strict';

angular.module('sdk').directive('print', function (_, $timeout, previewService, printService, urlService) {
    return {
        restrict: 'E',
        replace: true,
        templateUrl: 'print.html',
        link: function(scope, element, attributes) {
            scope.urlService = urlService;

            scope.state = 'loading';
            scope.snapshots = [];

            previewService.getSnapshots().then(function (snapshots) {
                scope.state = 'done';
                scope.snapshots = snapshots;

                $timeout(function () {
                    printService.print();
                });
            });
        }
    }
});
