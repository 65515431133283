'use strict';

angular.module('sdk').factory('FlashAdapter', function (swfobject, $q, $window, $timeout, colorList, consoleService) {

    function safeApply(scope, fn) {
        // Use scope$apply only if currently not in digest of apply phases
        // Typically flash executes callbacks synchronously, but there are situations when an unrelated flash event is received during the digest phase.
        // http://stackoverflow.com/questions/12729122/prevent-error-digest-already-in-progress-when-calling-scope-apply
        (scope.$$phase || scope.$root.$$phase) ? fn() : scope.$apply(fn);
    }

    var FlashAdapter = function FlashAdapter(options) {
        this.name = 'flash';
        this.options = angular.extend({}, options);
        this.isInitialized = false;
        this.isInitializationInProgress = false;
    };

    FlashAdapter.prototype.supported = function () {
        return swfobject.hasFlashPlayerVersion(this.options.version);
    };

    FlashAdapter.prototype.initialize = function (scope, callbacks) {
        if (this.isInitializationInProgress) {
            return;
        }
        this.isInitializationInProgress = true;

        consoleService.logEvent('FlashPreview', 'Initialize');

        this.scope = scope;
        this.callbacks = callbacks;

        var params = {
                quality: 'high',
                bgcolor: '#ffffff',
                allowscriptaccess: 'always',
                allowfullscreen: 'true',
                wmode: 'direct'
            },
            attrs = {
                id: 'flashPreview',
                name: 'flashPreview'
            };

        var flashvars = {
            'background': this.options.background,
            'assetVersion': this.options.assetVersion || '1'
        };

        this._bind();

        swfobject.embedSWF(this.options.path, 'flashPreviewPlaceholder', '100%', '100%', this.options.version, '', flashvars, params, attrs);
    };

    FlashAdapter.prototype.update = function (designData) {
        consoleService.logEvent('FlashPreview', 'Update');

        var container = this._container;

        this.callbacks.onRender && this.callbacks.onRender();

        if(container.updateDesignData) {
            try{
                container.updateDesignData({
                    'designData': designData
                });
            } catch(err){
                consoleService.reportError('FlashPreview - updateDesignData: ' + err.message);
            }
        } else {
            consoleService.reportError('FlashPreview - updateDesignData: function not found');
        }
    };

    FlashAdapter.prototype._bind = function () {
        var adapter = this,
            scope = this.scope,
            callbacks = this.callbacks;

        $window.onFlashPreviewAdapterReady = function () {
            consoleService.logEvent('FlashPreview', 'NotifyFlashReady');
            adapter._container = document.getElementById('flashPreview');

            adapter._container.updateColors(colorList.itemMap);

            adapter.isInitialized = true;
            adapter.isInitializationInProgress = false;

            safeApply(scope, function () {
                callbacks.onInitialized && callbacks.onInitialized();
            });
        };

        $window.onFlashPreviewRendered = function () {
            safeApply(scope, function () {
                consoleService.logEvent('FlashPreview', 'NotifyRender');
                callbacks.onRendered && callbacks.onRendered(true);
            });
        };

        $window.isFlashPreviewAdapterReady = function () {
            consoleService.logEvent('FlashPreview', 'CheckAdapterReady', adapter.isInitialized);
            // return adapter.isInitialized;
            return true;
        };

        $window.onFlashPreviewUserInteracted = function () {
            safeApply(scope, function () {
                consoleService.logEvent('FlashPreview', 'UserInteracted');
                callbacks.onFlashPreviewUserInteracted && callbacks.onFlashPreviewUserInteracted();
            });
        };

        $window.onFlashSnapshotsAsyncComplete = function (jobList) {
            if (!adapter.snapshotsDeferred) {
                return consoleService.reportError('FlashPreviewError', 'No active snapshot job running');
            }

            adapter.snapshotsDeferred.resolve(jobList);
            adapter.snapshotsDeferred = null;
        };

        $window.onFlashUncaughtError = function (message) {
            safeApply(scope, function () {
                consoleService.reportError('FlashUncaughtError', message);
            });
        };
    };

    FlashAdapter.prototype.getSnapshots = function () {
        var deferred = $q.defer();

        if (this.snapshotsDeferred) {
            deferred.reject('Already in progress');
            return deferred.promise;
        }

        this.snapshotsDeferred = deferred;

        var isRequestAccepted = this._container.getSnapshotsAsync(this.options.snapshotList);

        if (!isRequestAccepted) {
            deferred.reject('Flash request is not accepted');
        }

        return deferred.promise;
    };

    FlashAdapter.prototype.changeActiveView = function (viewName) {
        consoleService.logEvent('FlashPreview', 'UserChangedView', viewName);
        this._container.goToView(viewName);
    };

    return FlashAdapter;
});
