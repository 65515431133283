'use strict';

angular.module('sdk').directive('uploader', function (_, $rootScope, imageServerPath, urlService, iframeService) {
    return {
        restrict: 'E',
        replace: true,
        scope: {
            file: '=',
            position: '='
        },
        templateUrl: function (element, attributes) {
            return 'uploader/' + attributes.template + '.html';
        },
        link: function ($scope) {
            $scope.$watch('file.value()', function () {
                var fileData = $scope.file.value()
                

                $scope.fileName = fileData && fileData.filename;
            });
        },
        controller: function ($scope, $element) {
            $scope.supported = window.File && window.FileList && window.FormData && window.JSON;
            $scope.path = imageServerPath;
            $scope.progress = 0;
            $scope.status = 'none';
            $scope.fileName;

            $scope.previewAdapter = $rootScope.previewAdapter;

            var form = angular.element($element),
                field = form.find('input').eq(0);

            field.bind('change', function () {
                if (field.val() !== '') {
                    $scope.$apply(function () {
                        $scope.status = 'processing';
                    });

                    upload();
                }
            });

            function error() {
                $scope.status = 'error';
                field.val(null);
            }

            function success(fileData) {
                $scope.status = 'success';
                field.val(null);
                
                $scope.file.value(fileData);
            }

            function upload() {
                var xhr = new XMLHttpRequest()
                var formData = new FormData()
                var file = field[0].files[0];

                $scope.$apply(function () {
                    $scope.fileName = file.name;
                });

                formData.append('file', file);

                xhr.open('POST', $scope.path, true);

                xhr.onload = function (evt) {
                    if (xhr.readyState !== 4) {
                        return;
                    }
                    var status = xhr.status,
                        response = xhr.responseText;

                    if (xhr.getResponseHeader('content-type')
                        && ~xhr.getResponseHeader('content-type').indexOf('application/json')
                    ) {
                        try {
                            response = JSON.parse(response);
                        } catch (err) {
                            response = {};
                        }
                    }

                    if (!(200 <= status && status < 300)) {
                        $scope.$apply(function () {
                            error();
                        });
                    } else {
                        $scope.$apply(function () {
                            success({
                              id: response.id,
                              filename: file.name
                            });
                        });
                    }
                };

                xhr.onerror = function () {
                    $scope.$apply(function () {
                        error();
                    });
                };

                xhr.setRequestHeader('Accept', 'application/json');
                xhr.setRequestHeader('Cache-Control', 'no-cache');
                xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');

                xhr.send(formData);
            }
        }
    }
});
